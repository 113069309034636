import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AvailabilityCard from "../../../common/availabilityCard";
import SearchBar from "../../../common/commonSearchBar";
import Pagination from "../../../common/pagination";
import { availabilityStatusValue } from "../../../resources/status";
import { checkNumberOfGuest, isCorporate } from "../../../utils/index";
import CommonMoreInfoModel from "../../../common/commonMoreInfoModel";
import {
  getExperienceBookingList,
  experienceBookingsSearch,
  experienceBookingStatusUpdate,
  availabilitySinleData,
  pageId,
  experienceSearchValue,
  guestModalOpentrue,
  guestData,
} from "../../../actions/experienceBookingAction";
import AvailabilityRequestConfirmPopUp from "../../../common/availabilityRequestConfirmPopUp";
import GuestModal from "../../../common/checkAvailability/guestModal";
import ClosedModal from '../closeRequestModal';
import { closeRequestInputVal } from '../../../actions/holidayRequestAction';

const Followup = () => {
  const getFolloupList = useSelector(
    (state) => state.experienceBooking.experienceFollowup
  );
  const availabilityData = useSelector(
    (state) => state.experienceBooking.availabilitySingledata
  );
  const pageNum = useSelector((state) => state.experienceBooking.pageNum);
  const sectionID = useSelector(
    (state) => state.experienceBooking.experienceBookingsSecId
  );
  const searchVal = useSelector(
    (state) => state.experienceBooking.experienceFollowupSearchVal
  );
  const ffStartDate = useSelector(
    (state) => state.experienceBooking.experienceBookingStartDate
  );
  const ffEndDate = useSelector(
    (state) => state.experienceBooking.experienceBookingEndDate
  );

  const ff = useSelector(
    (state) => state.experienceBooking
  );
console.log(ff);
  const [requestConfirmPopUp, setRequestConfirmPopUp] = useState(false);
  const [openGuestModal, setOpenGuestModal] = useState(false);
  const [openMoreInfoModal, setOpenMoreInfoModal] = useState(false);
  const [moreInfoData, setMoreInfoData] = useState(false);
  const [
    availabilityConfirmationPopupdata,
    setAvailabilityConfirmationPopupdata,
  ] = useState({});
  const[iscopr,setIscorp]=useState()
  const [adultCount, setAdultCount] = useState(0);
  const [childrenCount, setChildrenCount] = useState(0);
  const [infantCount, setInfantCount] = useState(0);
  const [amountValue, setAmountValue] = useState("");
  const [scheduleError, setScheduleError] = useState(false);
  const [guestError, setGuestError] = useState(false);
  const [adultGuestError, setAdultGuestError] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [amountLengthError, setAmountLengthError] = useState(false);
  const [status, setStatus] = useState("");
  const [id, setId] = useState(null);
  const [requestType, setRequestType] = useState(null);
  const [requestModal, setRequestModal] = useState(false);
  const [closeRequestModalData, setCloseRequestModalData] = useState({});
  const [isclosingMessageBool, setIsclosingMessageBool] = useState(null);
  const closeRequestMessage = useSelector(state => state.holidayRequest.closeRequestMsg);
  const guestOpen = useSelector(
    (state) => state.experienceBooking.guestModalOpenTrue
  );
  const adultsGuest = useSelector(
    (state) => state.experienceBooking.adultsGuest
  );
  const childrenGuest = useSelector(
    (state) => state.experienceBooking.childrenGuest
  );
  const infantGuest = useSelector(
    (state) => state.experienceBooking.infantGuest
  );
  const dispatch = useDispatch();
  useEffect(() => {
    setAdultCount(availabilityData.adults);
    setChildrenCount(availabilityData.children);
    setInfantCount(availabilityData.infants);
  }, [availabilityData]);

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => (document.body.style.overflow = "unset");
  }, [openMoreInfoModal]);

  useEffect(() => {
    setTimeout(() => {
      setGuestError(false);
      setAdultGuestError(false);
      setScheduleError(false);
      setAmountError(false);
      setAmountLengthError(false);
    }, 2000);
  }, [scheduleError, guestError, amountError, amountLengthError]);

  useEffect(() => {
    if (
      searchVal === "" &&
      searchVal === undefined &&
      ffStartDate === null &&
      ffEndDate === null &&
      getFolloupList?.next_page_id === null &&
      getFolloupList?.current_page > 1 &&
      getFolloupList?.length === 0
    ) {
      dispatch(getExperienceBookingList(1, sectionID, "follow_up"));
      dispatch(pageId(1));
    } else if (
      sectionID !== undefined &&
      getFolloupList?.current_page > 1 &&
      getFolloupList?.next_page_id === null &&
      getFolloupList?.results.length === 0
    ) {
      dispatch(
        experienceBookingsSearch(
          1,
          "follow_up",
          searchVal,
          sectionID,
          ffStartDate,
          ffEndDate
        )
      );
      dispatch(pageId(1));
    } else if (
      searchVal !== "" &&
      searchVal === undefined &&
      getFolloupList?.current_page > 1 &&
      getFolloupList?.next_page_id === null &&
      getFolloupList?.results.length === 0
    ) {
      dispatch(pageId(1));
      dispatch(
        experienceBookingsSearch(
          1,
          "follow_up",
          searchVal,
          sectionID,
          ffStartDate,
          ffEndDate
        )
      );
    } else if (
      ffStartDate !== null &&
      ffEndDate !== null &&
      getFolloupList?.current_page > 1 &&
      getFolloupList?.next_page_id === null &&
      getFolloupList?.results.length === 0
    ) {
      dispatch(pageId(1));
      dispatch(
        experienceBookingsSearch(
          1,
          "follow_up",
          searchVal,
          sectionID,
          ffStartDate,
          ffEndDate
        )
      );
    } else if (
      sectionID !== undefined &&
      searchVal !== "" &&
      searchVal !== undefined &&
      ffStartDate !== null &&
      ffEndDate !== null &&
      getFolloupList?.current_page > 1 &&
      getFolloupList?.next_page_id === null &&
      getFolloupList?.results.length === 0
    ) {
      dispatch(pageId(1));
      dispatch(
        experienceBookingsSearch(
          1,
          "follow_up",
          searchVal,
          sectionID,
          ffStartDate,
          ffEndDate
        )
      );
    }
  }, [getFolloupList]);

  const moreInfoClick = (data) => {
    setMoreInfoData(data);
    setOpenMoreInfoModal(true);
  };

  const amountHandleChange = (e) => {
    setAmountValue(e.target.value);
  };

  const handlePagination = (e, value) => {
    if (
      searchVal !== undefined ||
      (ffStartDate !== null && ffEndDate !== null) ||
      sectionID !== null
    ) {
      dispatch(
        experienceBookingsSearch(
          value,
          "follow_up",
          searchVal,
          sectionID,
          ffStartDate,
          ffEndDate
        )
      );
      dispatch(pageId(value));
    } else {
      dispatch(getExperienceBookingList(value, sectionID, "follow_up"));
      dispatch(pageId(value));
    }
  };
  const refreshExperienceBooking = () => {
    setAmountValue("")
    if (searchVal !== "" && searchVal !== undefined) {
      dispatch(
        experienceBookingsSearch(
          pageNum,
          "follow_up",
          searchVal,
          sectionID,
          ffStartDate,
          ffEndDate
        )
      );
      dispatch(getExperienceBookingList(pageNum, sectionID, "confirmed"));
      dispatch(getExperienceBookingList(pageNum, sectionID, "follow_up"));
      dispatch(getExperienceBookingList(pageNum, sectionID, "cancelled"));
      dispatch(getExperienceBookingList(pageNum, sectionID, "follow_up"));
    } else if (ffStartDate !== null && ffEndDate !== null) {
      dispatch(
        experienceBookingsSearch(
          pageNum,
          "confirmed",
          searchVal,
          sectionID,
          ffStartDate,
          ffEndDate
        )
      );
      dispatch(
        experienceBookingsSearch(
          pageNum,
          "follow_up",
          searchVal,
          sectionID,
          ffStartDate,
          ffEndDate
        )
      );
      dispatch(
        experienceBookingsSearch(
          pageNum,
          "cancelled",
          searchVal,
          sectionID,
          ffStartDate,
          ffEndDate
        )
      );
      dispatch(
        experienceBookingsSearch(
          pageNum,
          "requested",
          searchVal,
          sectionID,
          ffStartDate,
          ffEndDate
        )
      );
    } else {
      dispatch(getExperienceBookingList(pageNum, sectionID, "requested"));
      dispatch(getExperienceBookingList(pageNum, sectionID, "confirmed"));
      dispatch(getExperienceBookingList(pageNum, sectionID, "cancelled"));
      dispatch(getExperienceBookingList(pageNum, sectionID, "follow_up"));
    }
  };

  const statusChangeHandler = (event, id, corporate, requestsType, data) => {
    console.log(data,"followupdata");
    setCloseRequestModalData(data)
    setAvailabilityConfirmationPopupdata(data);
    dispatch(availabilitySinleData(data));
    dispatch(guestModalOpentrue(false))
    let status = event.target.value;
    setStatus(status);
    setId(id);
    setIscorp(corporate)
    setRequestType(requestsType);
    if (status === "confirmed") {
      setRequestConfirmPopUp(true);
    }  else if(status === "cancelled")setRequestModal(true);
    else {
      dispatch(
        experienceBookingStatusUpdate(
          status,
          id,
          corporate,
          sectionID,
          null,
          refreshExperienceBooking
        )
      );
    }
  };
  const confirmedHandler = (data) => {
    setGuestError(false);
    setAdultGuestError(false);
    setScheduleError(false);
    setAmountError(false);
    setAmountLengthError(false);
    const guestData = {
      adults: guestOpen === true ? adultsGuest : adultCount,
      children: guestOpen === true ? childrenGuest : childrenCount,
      infants: guestOpen === true ? infantGuest : infantCount,
    };
    if (data.pack_schedule_type === "" && data.schedule.length === 0)
      setScheduleError(true);
    else if (
      adultsGuest === 0 &&
      adultCount === 0 &&
      childrenCount === 0 &&
      childrenGuest === 0 &&
      infantCount === 0 &&
      infantGuest === 0
    )
      setGuestError(true);
    else if (adultsGuest === 0 && (childrenGuest > 0 || infantGuest > 0))
      setAdultGuestError(true);
    else if (amountValue === "") setAmountError(true);
    else if (amountValue.length > 6) setAmountLengthError(true);
    else {
        console.log(amountValue,"amountValue");
      dispatch(
        experienceBookingStatusUpdate(
          status,
          id,
          availabilityData.corporate,
          sectionID,
          null,
          refreshExperienceBooking,
          data,
          guestData,
          amountValue
        )
      );
      setRequestConfirmPopUp(false);
      setAmountValue("");
    }
    // refreshExperienceBooking()
  };
  const updateAvailabilityConfirmationData = (data) => {
    setAvailabilityConfirmationPopupdata(data);
  };
  const handleClose = () => {
    setRequestConfirmPopUp(false);
    setOpenGuestModal(false);
    setAdultCount(availabilityData.adults);
    setChildrenCount(availabilityData.children);
    setInfantCount(availabilityData.infants);
    setAmountValue("")
  };

  const handleChange = (e) => {
    let searchInput = e.target.value;
    dispatch(
      experienceBookingsSearch(
        1,
        "follow_up",
        e.target.value,
        null,
        ffStartDate,
        ffEndDate
      )
    );
    dispatch(experienceSearchValue(searchInput, "follow_up"));
    dispatch(pageId(1));
  };

  const guestClickHandler = () => {
    setOpenGuestModal(true);
    dispatch(guestModalOpentrue(true));
  };

  const adultGuestIncrementHandler = () => {
    setAdultCount(adultCount + 1);
  };

  const adultGuestIDecrementHandler = () => {
    if (adultCount <= 0) {
      setAdultCount(0);
    } else {
      setAdultCount(adultCount - 1);
    }
  };

  const childrensGuestIncrementHandler = () => {
    setChildrenCount(childrenCount + 1);
  };

  const childrensGuestIDecrementHandler = () => {
    if (childrenCount <= 0) {
      setChildrenCount(0);
    } else {
      setChildrenCount(childrenCount - 1);
    }
  };

  const infantGuestIncrement = () => {
    setInfantCount(infantCount + 1);
  };

  const infantGuestDecrement = () => {
    if (infantCount <= 0) {
      setInfantCount(0);
    } else {
      setInfantCount(infantCount - 1);
    }
  };
  const doneClickHandler = () => {
    dispatch(guestData(adultCount, childrenCount, infantCount));
    setOpenGuestModal(false);
  };

  const closeRequestHandler = () => {
 
    setIsclosingMessageBool(false)
    if(closeRequestMessage === "")setIsclosingMessageBool(true)
    else{
      dispatch(
        experienceBookingStatusUpdate(
          status,
          id,
        iscopr,
          sectionID,
          closeRequestMessage,
          refreshExperienceBooking
        )
      );
        setRequestModal(false)

    }

    
    dispatch(closeRequestInputVal(''));
  };

const notToCancel=()=>{
    setRequestModal(false)
    setIsclosingMessageBool(false);

}

  const experienceDisplayList =
    getFolloupList &&
    getFolloupList?.results?.map((data) => (
      <AvailabilityCard
        packTitle={data.pack_title}
        location={data.address}
        schedule={
          Array.isArray(data.schedule)
            ? data.schedule.sort().map((scheduleDate, index) => {
                if (
                  data.schedule[index] ===
                  data.schedule[data.schedule.length - 1]
                ) {
                  return scheduleDate;
                } else {
                  return `${scheduleDate}, `;
                }
              })
            : typeof data.schedule === "object" &&
              data.schedule !== null &&
              data.schedule.to !== false
            ? `${data.schedule.from} to ${data.schedule.to}`
            : typeof data.schedule === "object" &&
              data.schedule !== null &&
              data.schedule.to === false
            ? `${data.schedule.from}`
            : data.schedule === null
            ? "N/A"
            : data.schedule
        }
        noOfGuest={checkNumberOfGuest(data.adults, data.children, data.infants)}
        messageTitle={data.special_request}
        specialRequest={data.special_request ? data.special_request : "N/A"}
        statusValues={availabilityStatusValue}
        statusChangeHandler={statusChangeHandler}
        moreInfoClick={() => moreInfoClick(data)}
        value={data.booking_status}
        refCode={data.ref_id ? `#${data.ref_id}` : "No ref id"}
        corporate={data.corporate}
        amount={
          data.amount === null
            ? ""
            : `Amount to be paid ${data.amount}`
        }
        // messageTitle={data.special_request}
        data={data}
        id={data.id}
      />
    ));
  return (
    <>
      <SearchBar searchVal={searchVal} handleChange={handleChange} />
      {getFolloupList && getFolloupList?.results?.length === 0 && (
        <div className="enquiry-card-holder">No Record Found!</div>
      )}
      {experienceDisplayList}
      <Pagination
        totalPage={getFolloupList?.total_pages}
        page={pageNum}
        onChange={(event, value) => handlePagination(event, value)}
      />
      <CommonMoreInfoModel
        open={openMoreInfoModal}
        modalClose={() => setOpenMoreInfoModal(false)}
        moreInfoData={moreInfoData}
        isAvailabilityReq={true}
        amount={` ${
          moreInfoData.amount === null
            ? ""
            : `Rs ${moreInfoData.amount}`
        }`}
        isCancellationMessage={false}
      />
      {requestConfirmPopUp && (
        <AvailabilityRequestConfirmPopUp
          open={requestConfirmPopUp}
          handleClose={handleClose}
          data={availabilityConfirmationPopupdata}
          guestClickHandler={guestClickHandler}
          dateRangeAvailabilityData={availabilityData}
          recurringAvailabilityData={availabilityData}
          specificAvailabilityData={availabilityData}
          availabilityData={availabilityData}
          changeData={updateAvailabilityConfirmationData}
          confirmClick={confirmedHandler}
          scheduleError={scheduleError}
          guestError={guestError}
          adultGuestError={adultGuestError}
          amountError={amountError}
          amountLengthError={amountLengthError}
          amountValue={amountValue}
          amountHandleChange={amountHandleChange}
        >
          {openGuestModal && (
            <GuestModal
              adultGuestIncrementHandler={adultGuestIncrementHandler}
              adultGuestIDecrementHandler={adultGuestIDecrementHandler}
              childrensGuestIncrementHandler={childrensGuestIncrementHandler}
              childrensGuestIDecrementHandler={childrensGuestIDecrementHandler}
              infantGuestIncrement={infantGuestIncrement}
              infantGuestDecrement={infantGuestDecrement}
              doneClickHandler={doneClickHandler}
              adultCount={adultCount}
              childrenCount={childrenCount}
              infantCount={infantCount}
            />
          )}
        </AvailabilityRequestConfirmPopUp>
        
      )}
          {requestModal && 
                <ClosedModal 
                  open={requestModal}
                  handleClose={notToCancel}
                  data={closeRequestModalData}
                  isclosingMessage={isclosingMessageBool}
                  closeRequestHandler={closeRequestHandler}
                />
              }
    </>
  );
};

export default Followup;
